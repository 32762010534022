<template>
  <div>
    <general-table
      ref="damenbiller"
      :api-url="apiUrl"
      :add-type="addType"
      :columns="columns"
      :type="type"
      :view-content="viewContent"
      :delete-content="false"
      :selectable="false"
      :export-table="false"
      guard="merchant_types"
    >
      <template #cell(name)="data">
        {{ data.item.name_ar }}
      </template>
      <template #cell(image)="data">

        <img
          v-if="data.item.image"
          :src="url+data.item.image"
          width="80"
        >
        <span v-else>   <feather-icon
          icon="ImageIcon"
          size="18"
        />
        </span>
      </template>
      <template #cell(created_at)="data">
        {{ timeFormat(data.item.created_at) }}
      </template>
      <template #filterBillers>

        <b-col
          cols="12"
          md="3"
        >
          <b-input-group>
            <b-form-select
              v-model="filter.cat_id "
              value-field="id"
              text-field="name"
              :options="categories"
            >
              <template #first>
                <b-form-select-option
                  disabled
                >
                  Category
                </b-form-select-option>
              </template>
            </b-form-select>

            <b-input-group-append v-if="filter.cat_id ">
              <b-button
                size="sm"
                @click="filter.cat_id = ''"
              >
                <span class="lead">
                  &times;
                </span>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-button
          variant="primary"
          :disabled="Object.values(filter).length < 1"
          @click="$refs.damenbiller.getAllData()"
        >
          Filter
        </b-button>

      </template>
    </general-table>
    <div>   <b-modal
      id="damenbiller"
      ref="genmodal"
      hide-footer
      centered
      no-close-on-backdrop
      title="Edit biller"
    ><Editbiller />
    </b-modal></div>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import Editbiller from './control-biller-aggregators/EditBillerAggregators.vue'

export default {
  components: { GeneralTable, Editbiller },
  data() {
    return {
      APIURL: 'billers',
      type: 'modal',
      url: 'https://stage.zerocash.co/storage/',
      viewContent: false,
      columns: [
        { key: 'id', sortable: true },
        { key: 'name', sortable: true },
        { key: 'cat', label: 'Category' },
        { key: 'image', label: 'Image' },
        { key: 'created_at', label: 'Created At' },
        { key: 'actions', label: 'Actions' },
      ],
      filter: {},
      types: '',
      categories: '',
    }
  },
  computed: {
    apiUrl() {
      let base = 'billers?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }

      return base.slice(0, -1)
    },

  },
  mounted() {
    this.getCategories()
  },
  methods: {
    timeFormat(date) {
      return moment(date).format(' DD MMM YYYY')
    },
    async getCategories() {
      await axios.get('categoryAggregators').then(res => {
        if (res.status === 200) {
          this.categories = res.data?.data
        }
      })
    },
    filterTypes(type) {
      const filteredType = this.categories.filter(item => item.text !== type)
      this.types = filteredType
    },
  },
}
</script>

<style>

</style>
